export default function Error({
    error,
    url,
    heading,
}: {
    error?: Error
    url?: string
    heading: string
}) {
    return (
        <div className="w-full h-screen bg-inventi-purple text-white p-4">
            <h1> {heading}</h1>
            <div className="h-1/2 bg-contain bg-no-repeat bg-center bg-[url('../public/error.svg')] text-inventi-purple text-center flex justify-center items-center pr-10 font-bold text-2xl">
                {error?.name ?? heading}
            </div>
            <p className="text-center text-lg text-white m-8">
                {error?.message}
            </p>
            <div className="flex justify-center"></div>
        </div>
    )
}
