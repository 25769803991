'use client'
import ErrorComponent from '@/components/Error'
import '../styles/globals.css'

export default function GlobalError({ error }: { error: Error }) {
    return (
        <html>
            <head>
                <title>Global error occurred</title>
            </head>
            <body>
                <ErrorComponent heading="Something went wrong!" error={error} />
            </body>
        </html>
    )
}
